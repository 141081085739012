<template>
  <div class="quest-library">
    <div class="">
      <div class="quest__refresh-icon">
        <!-- <h1 class="h1" >
          {{ $t("comp_questList--title") }}
        </h1> -->
        <i @click="getListOfAllQuests" class="bx bx-refresh box-icon"></i>
      </div>
      <transition name="fade" mode="out-in">
        <div class="quest-box">
          <div class="quest" v-for="quest in quests" :key="quest.id">
            <div
              class="quest__card"
              @click="loadQuest(quest.id)"
              v-bind:class="{ active: isCurrentQuest(quest.id) }"
            >
              <!-- <div class="card-header">{{ $t('comp_quest--cardTitle') }}</div> -->
              <div class="quest-title">
                {{
                  quest.general_information
                    ? quest.general_information[0].title.substring(0, 20)
                    : "NA"
                }}
              </div>
              <i
                @click="deleteQuest(quest.id)"
                name="trash"
                class="box-icon"
              ></i>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'QuestLibrary',
  data() {
    return {};
  },

  components: {},

  computed: {
    quests() {
      return this.$store.state.all_quests;
    },
  },

  methods: {
    ...mapGetters({
      getQuests: 'getQuests',
    }),

    ...mapActions({
      getListOfAllQuests: 'getListOfAllQuests',
    }),
    loadQuest(questId) {
      // this.getListOfAllQuests();
      this.$store.dispatch('setCurrentQuest', questId);
    },
    deleteQuest(questId) {
      // this.getListOfAllQuests();
      this.$store.dispatch('deleteMyQuest', questId);
    },
    isCurrentQuest(questId) {
      return this.$store.state.current_quest_id === questId;
    },
  },

  mounted() {
    this.quests.forEach((quest) => {
      document
        .querySelector(`#quest-${quest.id}`)
        .addEventListener('mouseover', () => {
          document.querySelector(
            `#quest-img-wrapper-${quest.id}`,
          ).style.visibility = 'visible';
        });

      document
        .querySelector(`#quest-${quest.id}`)
        .addEventListener('mouseout', () => {
          document.querySelector(
            `#quest-img-wrapper-${quest.id}`,
          ).style.visibility = 'hidden';
        });
    });
  },

  created() {
    this.getListOfAllQuests();
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/abstracts/variables";

.quest-library {
  padding-bottom: 3re;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.btn-maxmin {
  opacity: 0.8;
  transition: all ease-out 200ms;

  &:hover {
    cursor: pointer;
    opacity: 1;
    transition: all ease-out 200ms;
  }
}
.book-img {
  height: 100%;
  width: 100%;
}

.quest-box {
  display: flex;
  flex-wrap: wrap;

  .quest {
    display: flex;
    align-items: center;
    // padding: 0.5rem 1rem;
    width: 100%;

    &__card {
      // display: flex;
      // align-items: center;
      border-bottom: 0.0625rem solid $border-color-1;
      border-radius: 0.3rem;
      padding: 2.5rem 0.5rem;
      // min-width: 15rem;
      width: 100%;

      &:hover {
        cursor: pointer;
        background: $lightGray;
      }
    }
  }
}

.quest__refresh-icon {
  position: absolute;
  top: 0;
  right: 1rem;
}

.quest-title {
  flex: 1;
  margin-left: 1em;
  word-break: break-word;
}
.card-body {
  padding: unset !important;
  display: flex;
  align-items: center;
}
.book-wrapper {
  &:hover {
    cursor: pointer;
  }
}
.book {
  margin: 1em;

  &-title {
    font-size: 1rem;
  }

  &-img-wrapper {
    min-width: 40%;
    height: auto;
    margin-right: 1em;
  }
}
.quest-img-wrapper {
  order: 2;
  visibility: hidden;
}
.active {
  background-color: $tertiaryYellow;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: $white;
  background-clip: border-box;
  border-bottom: 0.03125rem solid #00000030;
  border-radius: 0.3125rem;
}
</style>
